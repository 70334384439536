import React, { useState } from "react";
import { FaQuestionCircle, FaRegSmile } from "react-icons/fa";

const FAQ = () => {
  const [faqOpenIndex, setFaqOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpenIndex((prev) => (prev === index ? null : index));
  };

  const faqItems = [
    {
      question: "🔄 What is Chronocult?",
      answer:
        "Chronocult is a web-based timing solution built for time trials. It’s easy to use on any device – iOS, Android, or desktop – simply visit chronocult.com, log in, and you’re ready to go.",
    },
    {
      question: "⏱ How does Chronocult work?",
      answer:
        "After logging in, you create an event and add your racers. You can easily group or tag racers to filter them. When a racer starts, click ‘Start’ to mark them on course, and click ‘Stop’ when they finish. The leaderboard updates in real time.",
    },
    {
      question: "👥 Can I collaborate with others?",
      answer:
        "Absolutely! Chronocult supports multi-user collaboration. You can invite other users—or even guests via QR code—to assist with timing. This means one person can start the timer while another stops it, with all data syncing instantly across devices.",
    },
    {
      question: "💳 How is payment handled?",
      answer:
        "Chronocult operates on a pay-as-you-go model, so you only pay for what you use. Plus, we give you free credits to get started, making it both affordable and flexible for teams and organizations.",
    },
    {
      question: "🏃 Who can use Chronocult?",
      answer:
        "Chronocult is perfect for teams, coaches, schools, and anyone involved in sports that require accurate time tracking between two points or around a lap. It's designed to be simple yet powerful for managing time trials.",
    },
    {
      question: "📊 How do I view my results?",
      answer:
        "Your event dashboard displays racers on course in order and updates the leaderboard automatically as racers finish. This real-time view helps you quickly analyze performance during and after the event.",
    },
    {
      question: "🔗 What about other tools?",
      answer:
        "While Chronocult focuses on real-time timing, we also offer the Victorise Video Tool for detailed video analysis and telemetry. This tool integrates data from GPS, power meters, heart rate monitors, brake sensors, suspension sensors, and more for a comprehensive performance review.",
    },
  ];


  return (
      <ul className="faq-list">
        {faqItems.map((item, index) => (
          <li key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFaq(index)}>
              <FaQuestionCircle style={{ marginRight: "5px" }} />
              {item.question}
            </div>
            {faqOpenIndex === index && (
              <div className="faq-answer">
                {item.answer}{" "}
                <span role="img" aria-label="smile">
                  <FaRegSmile />
                </span>
              </div>
            )}
          </li>
        ))}
      </ul>

  );
};

export default FAQ;