// src/components/GuestEvents.js
import React, { useState, useEffect } from "react";
import { collection, query, where, onSnapshot, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useLocation, Link } from "react-router-dom";

const GuestEvents = () => {
  const [events, setEvents] = useState([]);
  const [groupName, setGroupName] = useState("");
  const location = useLocation();

  // Get the sharegroup id from URL, e.g., /guest-events?id=SHAREGROUP_ID
  const params = new URLSearchParams(location.search);
  const shareGroupId = params.get("id");

  // Fetch the sharegroup document to get its name
  useEffect(() => {
    if (!shareGroupId) return;
    const fetchGroupName = async () => {
      const groupDocRef = doc(db, "sharegroups", shareGroupId);
      const groupSnap = await getDoc(groupDocRef);
      if (groupSnap.exists()) {
        setGroupName(groupSnap.data().name);
      } else {
        setGroupName("Unknown Group");
      }
    };
    fetchGroupName();
  }, [shareGroupId]);

  // Fetch events shared to this group
  useEffect(() => {
    if (!shareGroupId) return;
    const eventsRef = collection(db, "events");
    const q = query(eventsRef, where("sharedGroupId", "==", shareGroupId));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const eventsList = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      // Optionally sort events (e.g., by createdAt descending)
      eventsList.sort((a, b) => b.createdAt - a.createdAt);
      setEvents(eventsList);
    });
    return () => unsubscribe();
  }, [shareGroupId]);

  return (
    <div style={{ padding: "20px" }}>
      <h2>Events Shared with Group: {groupName}</h2>
      {events.length === 0 ? (
        <p>No events available in this group.</p>
      ) : (
        <ul style={{ listStyle: "none", padding: 0 }}>
          {events.map((ev) => (
            <li
              key={ev.id}
              style={{
                marginBottom: "10px",
                borderBottom: "1px solid #ccc",
                padding: "10px 0",
              }}
            >
              <strong>{ev.name}</strong>
              <br />
              <small>{ev.createdAt ? new Date(ev.createdAt).toLocaleString() : ""}</small>
              <br />
              <Link to={`/timer?eventId=${ev.id}&guestViewerKey=${ev.guestViewerKey}`}>
                View Event
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default GuestEvents;