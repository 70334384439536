import React, { useEffect,useState } from 'react';
import { Link } from "react-router-dom";
import { FaApple, FaWindows, FaClock, FaTimes, FaVideo, FaCamera, FaQuestionCircle, FaRegSmile, FaTrash, FaShareAlt, FaUnlink, FaArrowRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import "../App.css"; // Ensure global styles are applied
import FAQ from "./FAQ";

const Landing = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
 const [demoModalTimerOpen, setDemoModalTimerOpen] = useState(false);

//   useEffect(() => {
//     if (user) {
//       navigate('/homepage');
//     }
//   }, [user, navigate]);

  return (
    <div className="landing-container">
      {/* Hero Section */}
      <section className="section alternate-section">
        <div className="section-content-wrapper">
          <div className="section-content">
            <h1 className="hero-title">Your Timing Companion</h1>
            <p className="hero-description">
              Simple, web-based, and ready to use on any device – iOS, Android, or desktop. 
              Log in, and you’re set!
            </p>
                        <span
                        style={{color: "white"}}
                        >
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                          onClick={() => setDemoModalTimerOpen(true)}
                        >
                          <FaVideo style={{ marginRight: "5px" }} /> view demo video
                        </button>
                        </span>
            {/* Buttons for Login & Signup */}
            <div className="auth-buttons">
              <Link to="/login">
                <button className="primary-btn" 
                  style={{ backgroundColor: "transparent", 
                  color: "white", 
                  border: "2px solid white",
                  borderRadius: "10px",
                  marginRight: "20px",
                  width: "200px" }}>
                  Sign In
                </button>
              </Link>
              <Link to="/login?signup=true">
                <button className="secondary-btn"
                  style={{ backgroundColor: "white", 
                  color: "#1aa6b7", 
                  border: "0px solid white",
                  borderRadius: "10px",
                  width: "200px" }}>
                  Create Free Account
                </button>
              </Link>
            </div>
          </div>
          <div className="section-image">
            <img src="img/illus-01.png" alt="Any Device, Anytime" />
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="section">
        <div className="section-content-wrapper">
          <div className="section-image">
            <img src="img/illus-02.png" alt="Time Trial Workflow" />
          </div>
          <div className="section-content">
            <h2 className="section-title">Built for Time Trials</h2>
            <p className="section-description">
              Chronocult is perfectly designed for time trial events. Log in, create your event, and add your racers. Easily tag and group racers for quick filtering.
              When a racer starts, simply click “Start” – the racer is marked as on course. Click “Stop” when they finish, and the leaderboard updates automatically in real time.
            </p>
          </div>
        </div>
      </section>

      {/* Multi-User Collaboration Section */}
      <section className="section alternate-section">
        <div className="section-content-wrapper">
          <div className="section-content">
            <h2 className="section-title">Real-Time Multi-Device Timing</h2>
            <p className="section-description">
              Work as a team – invite any user or even a guest by simply scanning a QR code. Have one person start the timer and another at the finish.
              All data syncs in real time across devices, with racers on track appearing in order and the leaderboard updating instantly.
            </p>
          </div>
          <div className="section-image">
            <img src="img/illus-03.png" alt="Real-Time Sync" />
          </div>
        </div>
      </section>

      {/* Affordable Pay-As-You-Go Section */}
      <section className="section">
        <div className="section-content-wrapper">
          <div className="section-image">
            <img src="/img/illus-04.png" alt="Affordable Timing" />
          </div>
          <div className="section-content">
            <h2 className="section-title">Pay As You Go</h2>
            <p className="section-description">
              Chronocult is affordable and comes with free credits to get you started. No long-term contracts—just simple, pay-as-you-go pricing so you can focus on your event.
            </p>
          </div>
        </div>
      </section>

      {/* Who It's For Section */}
      <section className="section alternate-section">
        <div className="section-content-wrapper">
          <div className="section-content">
            <h2 className="section-title">For Teams, Coaches, and Schools</h2>
            <p className="section-description">
              Whether you're part of a sports team, a coach organizing training sessions, or a school managing races, Chronocult provides an easy and efficient way to time events between two points or around a lap.
            </p>
          </div>
          <div className="section-image">
            <img src="/img/illus-05.png" alt="For Every Sport" />
          </div>
        </div>
      </section>

      {/* Other Tools Section */}
      <section className="section alternate-section" style={{ backgroundColor: "#dadada", color: "#000" }}>    
        <div className="section-content-wrapper">
          <div className="section-content" style={{ width: "100%" }}>
            <h2 className="section-title">Other Tools</h2>
            <p className="section-description"
            style={{ backgroundColor: "#dadada", color: "#000"}}
            >
              And don’t forget our <a href='https://victorise.co'>Victorise Video Tool (http://victorise.co)</a> – perfect for analyzing video and telemetry data. Integrate GPS, power meter, heart rate, brake, suspension sensors, and more to gain deeper insights into your performance.
            </p>
          </div>
        </div>
      </section>

      {/* Final Call-to-Action Section */}
      <section className="section">
        <div className="section-content-wrapper">
          <div className="section-image">
            <img src="/img/illus-06.png" alt="Get Started" />
          </div>
          <div className="section-content">
            <h2 className="section-title">Get Started with Chronocult Today</h2>
            <p className="section-description">
              It’s simple: log in, and create your event in minutes. Track your time trials with precision and collaborate with your team in real time.
            </p>
            <Link to="/login?signup=true" className="primary-btn">
              Create an Account
            </Link>
          </div>
        </div>
      </section>


      {/* FAQ Section */}
      <section className="section alternate-section">
        <div className="section-content-wrapper">
          <div className="section-content" style={{ width: "100%" }}>
            <h2 className="section-title">FAQ</h2>
            <FAQ />
          </div>
        </div>
      </section>

      {demoModalTimerOpen && (
        <div className="modal-overlay" onClick={() => setDemoModalTimerOpen(false)}>
          <div className="modal" onClick={(e) => e.stopPropagation()} style={{ position: "relative", width: "80%" }}>
            <button className="modal-close-btn" onClick={() => setDemoModalTimerOpen(false)}>
              <FaTimes />
            </button>
            <h3>Demo Video </h3>
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
              <iframe
                title="Demo Video Timer"
                src="https://www.youtube.com/embed/lkSw70TJ71o"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      )}


    </div>
    
  );
};

export default Landing;