// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbbRRQBWaxAKx6dfI40i_aBHNbVFbJONM",
  authDomain: "victorise-video-tool.firebaseapp.com",
  projectId: "victorise-video-tool",
  storageBucket: "victorise-video-tool.firebasestorage.app",
  messagingSenderId: "493819678818",
  appId: "1:493819678818:web:432198ad1390d11a59c13b",
  measurementId: "G-V00JE8VYJ8"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

// Export the Auth instance
export const auth = getAuth(app);

// Export the Firestore DB
export const db = getFirestore(app);

// Enable offline persistence
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open; persistence can only be enabled in one tab at a time.
    console.error("Persistence failed: multiple tabs open");
  } else if (err.code === 'unimplemented') {
    // The current browser does not support all features required to enable persistence
    console.error("Persistence is not available in this browser");
  }
});

export const storage = getStorage(app);