// EventModal.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { useAuth } from "../contexts/AuthContext";

const EventModal = ({ onClose, onSelectEvent }) => {
  const { user } = useAuth();
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    if (!user) return;
    const eventsRef = collection(db, 'events');
    const unsubscribe = onSnapshot(eventsRef, (snapshot) => {
      const eventsList = snapshot.docs
        .map(docSnap => ({ id: docSnap.id, ...docSnap.data() }))
        // If you want to restrict to events the user owns, adjust here.
        .filter(event => event.userId === user.uid);
      setEvents(eventsList);
    });
    return () => unsubscribe();
  }, [user]);

  const handleDeleteEvent = async (eventId) => {
    try {
      await deleteDoc(doc(db, 'events', eventId));
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const sortEvents = (eventsList) => {
    return eventsList.sort((a, b) => {
      if (sortType === "name") {
        return sortOrder === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      } else if (sortType === "date") {
        const aTime = a.createdAt || 0;
        const bTime = b.createdAt || 0;
        return sortOrder === "asc" ? aTime - bTime : bTime - aTime;
      }
      return 0;
    });
  };

  const filteredEvents = events.filter(event =>
    event.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedEvents = sortEvents(filteredEvents);

  return (
    <div style={{ position: 'relative', padding: '20px' }}>
      {/* Cross button in the top right */}
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'transparent',
          border: 'none',
          fontSize: '1.5em',
          color: '#000',
          cursor: 'pointer'
        }}
      >
        &times;
      </button>
      <h2 style={{ marginBottom: '20px' }}>Select an Event</h2>
      <div style={{ marginBottom: "10px", display: 'flex', gap: '10px' }}>
        <input 
          type="text" 
          placeholder="🔍 Search events..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ padding: "5px", flex: 1 }}
        />
        <button 
          onClick={() => {
            if(sortType === "name") {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            } else {
              setSortType("name");
              setSortOrder("asc");
            }
          }}
          style={{
            backgroundColor: "#13A1A8",
            color: "#fff",
            border: "none",
            padding: "10px 15px",
            borderRadius: "5px",
            cursor: "pointer"
          }}
        >
          Sort by Name {sortType === "name" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </button>
        <button 
          onClick={() => {
            if(sortType === "date") {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            } else {
              setSortType("date");
              setSortOrder("asc");
            }
          }}
          style={{
            backgroundColor: "#FE6C62",
            color: "#fff",
            border: "none",
            padding: "10px 15px",
            borderRadius: "5px",
            cursor: "pointer"
          }}
        >
          Sort by Date {sortType === "date" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </button>
      </div>
      <ul style={{ listStyle: "none", padding: 0 }}>
        {sortedEvents.map(event => (
          <li key={event.id} style={{ marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #ccc", paddingBottom: "5px" }}>
            <div>
              <strong>{event.name}</strong>
              <br />
              <small>{event.createdAt ? new Date(event.createdAt).toLocaleString() : ""}</small>
            </div>
            <div>
              <button
                onClick={() => onSelectEvent(event)}
                style={{
                  backgroundColor: "#13A1A8",
                  color: "#fff",
                  border: "none",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginRight: "10px"
                }}
              >
                Select
              </button>
              <button
                onClick={() => handleDeleteEvent(event.id)}
                style={{
                  backgroundColor: "#FF4D4D",
                  color: "#fff",
                  border: "none",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  cursor: "pointer"
                }}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EventModal;