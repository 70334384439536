// RacerModal.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

const RacerModal = ({ onClose, onSelectRacers, eventOwnerId }) => {
  const [racers, setRacers] = useState([]);
  const [filterGroup, setFilterGroup] = useState("");
  const [distinctGroups, setDistinctGroups] = useState([]);
  const [selectedRacers, setSelectedRacers] = useState([]);

  // Fetch racers and compute distinct groups on mount
  useEffect(() => {
    const fetchRacers = async () => {
      const racersRef = collection(db, "timer_racers");
      // Filter racers to only those created by the event owner.
      const q = query(racersRef, where("createdBy", "==", eventOwnerId));
      const querySnapshot = await getDocs(q);
      const racerList = [];
      querySnapshot.forEach(docSnap => {
        racerList.push({ id: docSnap.id, ...docSnap.data() });
      });
      setRacers(racerList);
      // Compute distinct groups
      const groupsSet = new Set();
      racerList.forEach(racer => {
        if (racer.groups) {
          racer.groups.forEach(g => groupsSet.add(g));
        }
      });
      setDistinctGroups(Array.from(groupsSet).sort());
    };
    fetchRacers();
  }, [eventOwnerId]);

  // Filter racers based on the selected group
  const filteredRacers = racers.filter(racer => {
    if (filterGroup === "") return true;
    return racer.groups && racer.groups.includes(filterGroup);
  });

  const toggleSelectRacer = (racer) => {
    const isSelected = selectedRacers.some(r => r.id === racer.id);
    if (isSelected) {
      setSelectedRacers(selectedRacers.filter(r => r.id !== racer.id));
    } else {
      setSelectedRacers([...selectedRacers, racer]);
    }
  };

  return (
    <div
      className="modal-overlay"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <div
        className="modal-content"
        style={{
          background: "#fff",
          padding: "20px",
          borderRadius: "8px",
          width: "90%",
          maxWidth: "600px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <h3>Select Racers</h3>
        {/* Filter dropdown */}
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="filterGroup" style={{ marginRight: "5px" }}>
            Filter by group:
          </label>
          <select
            id="filterGroup"
            value={filterGroup}
            onChange={(e) => setFilterGroup(e.target.value)}
            style={{ padding: "5px" }}
          >
            <option value="">All</option>
            {distinctGroups.map((group, index) => (
              <option key={index} value={group}>
                {group}
              </option>
            ))}
          </select>
        </div>
        {/* Racers table */}
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ borderBottom: "1px solid #ccc" }}>
              <th style={{ textAlign: "left", padding: "5px" }}>Name</th>
              <th style={{ textAlign: "left", padding: "5px" }}>Group</th>
              <th style={{ padding: "5px", textAlign: "center" }}>Select</th>
            </tr>
          </thead>
          <tbody>
            {filteredRacers.map((racer) => (
              <tr key={racer.id} style={{ borderBottom: "1px solid #eee" }}>
                <td style={{ padding: "5px" }}>{racer.name}</td>
                <td style={{ padding: "5px" }}>
                  {racer.groups && racer.groups.length > 0
                    ? racer.groups.join(", ")
                    : "No group"}
                </td>
                <td style={{ padding: "5px", textAlign: "center" }}>
                  <input
                    type="checkbox"
                    checked={!!selectedRacers.find(r => r.id === racer.id)}
                    onChange={() => toggleSelectRacer(racer)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Action buttons */}
        <div style={{ marginTop: "15px", display: "flex", justifyContent: "space-between" }}>
        <button
            onClick={onClose}
            style={{
              padding: "10px 15px",
              backgroundColor: "#ccc",
              border: "none",
              color: "#333",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => onSelectRacers(selectedRacers)}
            style={{
              padding: "10px 15px",
              backgroundColor: "#13A1A8",
              border: "none",
              color: "#fff",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Add Selected Racers
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default RacerModal;