// Homepage.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDoc, setDoc, getDocs, query, where, doc, onSnapshot, deleteDoc, updateDoc, addDoc, arrayRemove, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import "../App.css";
import creditCosts from "../config/creditCosts";
import { useToast } from "../components/Toast";
import { useAuth } from "../contexts/AuthContext";
import { FaTimes, FaVideo, FaQuestionCircle, FaRegSmile, FaTrash, FaShareAlt, FaUnlink, FaPencilAlt } from "react-icons/fa";
import Tooltip from "../components/Tooltip";

const Homepage = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { showToast } = useToast();
  const [runs, setRuns] = useState([]);
  const [filteredRuns, setFilteredRuns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [faqOpenIndex, setFaqOpenIndex] = useState(null);
  const [userData, setUserData] = useState(null);
  const [sharedRuns, setSharedRuns] = useState([]);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [selectedRunToShare, setSelectedRunToShare] = useState(null);
  const [shareUsername, setShareUsername] = useState("");
  const [demoModalMainOpen, setDemoModalMainOpen] = useState(false);
  const [demoModalTimerOpen, setDemoModalTimerOpen] = useState(false);
  const [racers, setRacers] = useState([]);
  const [events, setEvents] = useState([]);
  const [shareGroupId, setShareGroupId] = useState(null);
  // New state for Manage Racers
  const [newRacerName, setNewRacerName] = useState("");
  const [racerSortType, setRacerSortType] = useState("name"); // "name" or "group"
  const [editingRacerId, setEditingRacerId] = useState(null);
  const [renameInput, setRenameInput] = useState("");
  const [tagInputs, setTagInputs] = useState({}); // { [racerId]: inputValue }
  const [editingEventId, setEditingEventId] = useState(null);
const [eventNameEdit, setEventNameEdit] = useState("");


// At the top of your component, add a new state for group filtering:
const [groupFilter, setGroupFilter] = useState("");
// Add a new state to manage toggling the group input per racer
const [showGroupInput, setShowGroupInput] = useState({});
// Compute distinct groups from racers for the filter dropdown
const distinctGroups = Array.from(
  new Set(racers.flatMap((racer) => (racer.groups ? racer.groups : [])))
).sort();

const generateDiceBearAvatar = (username) => {
  return `https://api.dicebear.com/7.x/identicon/svg?seed=${encodeURIComponent(username)}`;
};

const handleUpdateEventName = async (eventId, newName) => {
  try {
    await updateDoc(doc(db, "events", eventId), { name: newName });
    setEditingEventId(null);
  } catch (error) {
    console.error("Error updating event name:", error);
  }
};


  // Sorting racers before rendering
// Sorting racers before rendering
const sortedRacers = racers.slice().sort((a, b) => {
  if (racerSortType === "name") {
    return sortOrder === "asc"
      ? a.name.localeCompare(b.name)
      : b.name.localeCompare(a.name);
  } else if (racerSortType === "created") {
    // Assumes each racer document has a 'createdAt' field (a timestamp)
    const aTime = a.createdAt ? a.createdAt.seconds : 0;
    const bTime = b.createdAt ? b.createdAt.seconds : 0;
    return bTime - aTime; // most recent first
  }
  return 0;
});

// Apply group filter on top of the sorted list
const filteredRacers = sortedRacers.filter(
  (racer) => groupFilter === "" || (racer.groups && racer.groups.includes(groupFilter))
);
  // Fetch user data (for credits)
  useEffect(() => {
    if (!user) {
      setUserData(null);
      return;
    }
    const docRef = doc(db, "users", user.uid);
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        setUserData(snapshot.data());
      }
    });
    return () => unsubscribe();
  }, [user]);

  // Fetch runs for logged-in user
  useEffect(() => {
    const fetchRuns = async () => {
      try {
        const runsRef = collection(db, "runs");
        const q = query(runsRef, where("userId", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const runsList = [];
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          runsList.push({
            id: docSnap.id,
            ...data,
            date: data.timestamp?.seconds
              ? new Date(data.timestamp.seconds * 1000).toLocaleString()
              : "Unknown Date",
          });
        });
        setRuns(runsList);
        setFilteredRuns(runsList);
      } catch (error) {
        console.error("Error fetching runs", error);
      }
    };
    if (user) fetchRuns();
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate("/landing"); // change this path to your landing page route
    }
  }, [user, navigate]);

  useEffect(() => {
    if (!user) return;
    const shareGroupRef = doc(db, "sharegroups", user.uid);
    getDoc(shareGroupRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          setShareGroupId(docSnap.id);
        } else {
          // Create the sharegroup document for this user with default name "public"
          setDoc(shareGroupRef, {
            ownerId: user.uid,
            name: "public",
            createdAt: Date.now(),
          })
            .then(() => {
              setShareGroupId(user.uid);
            })
            .catch((error) => {
              console.error("Error creating sharegroup:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching sharegroup:", error);
      });
  }, [user]);

  // Fetch shared runs for logged-in user
  useEffect(() => {
    const fetchSharedRuns = async () => {
      try {
        const runsRef = collection(db, "runs");
        const q = query(runsRef, where("sharedWith", "array-contains", user.uid));
        const querySnapshot = await getDocs(q);
        const sharedList = [];
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          sharedList.push({
            id: docSnap.id,
            ...data,
            date: data.timestamp?.seconds
              ? new Date(data.timestamp.seconds * 1000).toLocaleString()
              : "Unknown Date",
          });
        });
        setSharedRuns(sharedList);
      } catch (error) {
        console.error("Error fetching shared runs", error);
      }
    };
    if (user) fetchSharedRuns();
  }, [user]);

  // Fetch events for logged-in user
  useEffect(() => {
    if (!user) return;
    const eventsRef = collection(db, "events");
    const q = query(
      eventsRef,
      where("admins", "array-contains-any", [user.uid, user.email])
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const eventsList = snapshot.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data()
      }));
      eventsList.sort((a, b) => b.createdAt - a.createdAt);
      setEvents(eventsList);
    });
    return () => unsubscribe();
  }, [user]);

  // Fetch racers from timer_racers collection and sort them
  useEffect(() => {
    if (!user) return;
    const racersRef = collection(db, "timer_racers");
    // Add a where clause to filter by createdBy
    const q = query(racersRef, where("createdBy", "==", user.uid));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const racersList = snapshot.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data()
      }));
      // Default sorting by name; sorting will be applied in render
      racersList.sort((a, b) => a.name.localeCompare(b.name));
      setRacers(racersList);
    });
    return () => unsubscribe();
  }, [user]);

  // Delete a run
  const handleDeleteRun = async (runId) => {
    try {
      await deleteDoc(doc(db, "runs", runId));
      setRuns(prevRuns => prevRuns.filter(run => run.id !== runId));
      setFilteredRuns(prevRuns => prevRuns.filter(run => run.id !== runId));
    } catch (error) {
      console.error("Error deleting run:", error);
    }
  };
// Add a new function to delete an event:
const handleDeleteEvent = async (eventId) => {
  try {
    await deleteDoc(doc(db, "events", eventId));
  } catch (error) {
    console.error("Error deleting event", error);
  }
};

  // Logout
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout error", error);
    }
  };

  // Search runs
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = runs.filter((run) =>
      run.runName.toLowerCase().includes(value)
    );
    setFilteredRuns(filtered);
  };

  // Sort runs
  const sortRuns = (column) => {
    let newOrder = sortOrder;
    if (sortType === column) {
      newOrder = sortOrder === "asc" ? "desc" : "asc";
      setSortOrder(newOrder);
    } else {
      setSortType(column);
      newOrder = "asc";
      setSortOrder("asc");
    }
    const sorted = [...filteredRuns].sort((a, b) => {
      if (column === "name") {
        return newOrder === "asc"
          ? a.runName.localeCompare(b.runName)
          : b.runName.localeCompare(a.runName);
      } else if (column === "date") {
        const aTime = a.timestamp ? a.timestamp.seconds : 0;
        const bTime = b.timestamp ? b.timestamp.seconds : 0;
        return newOrder === "asc" ? aTime - bTime : bTime - aTime;
      }
      return 0;
    });
    setFilteredRuns(sorted);
  };

  // Rename a racer
  const handleRenameRacer = async (racerId, newName) => {
    try {
      await updateDoc(doc(db, "timer_racers", racerId), { name: newName });
      setEditingRacerId(null);
    } catch (error) {
      console.error("Error renaming racer", error);
    }
  };

  const handleRemoveRacer = async (racerId) => {
    try {
      await deleteDoc(doc(db, "timer_racers", racerId));
    } catch (error) {
      console.error("Error removing racer", error);
    }
  };

  // Add a new racer
  const handleAddRacer = async () => {
    console.log("Adding new racer:");
    
    if (!newRacerName.trim()) {
      showToast("Please enter a racer name.");
      return;
    }
    if (userData.credits < creditCosts.CREATE_RACER) {
      showToast("Not enough credits to create a racer.");
      return;
    }
    try {
      // Deduct credits from the user.
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, { credits: userData.credits - creditCosts.CREATE_RACER });
      setUserData((prev) => ({ ...prev, credits: prev.credits - creditCosts.CREATE_RACER }));
  
      const racersRef = collection(db, "timer_racers");
      await addDoc(racersRef, { 
        name: newRacerName, 
        groups: [], 
        createdBy: user.uid  // Ensure you record who created this racer.
      });
      showToast("Racer added successfully!");
      setNewRacerName("");
    } catch (error) {
      console.error("Error adding racer", error);
      showToast("Error adding racer, please try again.");
    }
  };

  // Add a tag to a racer
  const handleAddTag = async (racerId, tag) => {
    if (!tag.trim()) return;
    try {
      await updateDoc(doc(db, "timer_racers", racerId), { groups: arrayUnion(tag) });
      setTagInputs(prev => ({ ...prev, [racerId]: "" }));
    } catch (error) {
      console.error("Error adding tag", error);
    }
  };

  // Remove a tag from a racer
  const handleRemoveTag = async (racerId, tag) => {
    try {
      await updateDoc(doc(db, "timer_racers", racerId), { groups: arrayRemove(tag) });
    } catch (error) {
      console.error("Error removing tag", error);
    }
  };

  // Toggle FAQ answer display
  const toggleFaq = (index) => {
    setFaqOpenIndex((prev) => (prev === index ? null : index));
  };


  // FAQ items
  const faqItems = [
    {
      question: "⏱️ What is Chronocult?",
      answer:
        "Chronocult is a straightforward timing system designed for amateur teams, clubs, and individuals. It offers a simple way to record race times without complicated setups, so you can focus on having fun!",
    },
    {
      question: "🚀 How do I start a new event?",
      answer:
        "To get started, simply click on the 'Create New Event' button on the homepage. Follow the on-screen instructions to set up your timing session, and you'll be off and running in just a few steps.",
    },
    {
      question: "🎯 How does the timer feature work?",
      answer:
        "Chronocult uses two devices—one at the start and one at the finish—to capture race times quickly and easily. This minimal setup keeps the process simple and user-friendly.",
    },
    {
      question: "🤝 How can I collaborate on an event?",
      answer:
        "Collaboration is easy with Chronocult. You can invite another user as an admin to your event, allowing you to share responsibilities and manage the timing session together.",
    },
    {
      question: "📤 Can I export my event results?",
      answer:
        "Yes, you can export your event results as a CSV file. This feature makes it easy to review your timings or share the data with others using your favorite spreadsheet software.",
    },
    {
      question: "💳 How do credits work?",
      answer:
        "Each timing session uses a specific number of credits that are automatically deducted from your balance. It's a simple way to keep track of your usage as you go.",
    },
    {
      question: "🛒 How do I purchase more credits?",
      answer:
        "When you need more credits, just click the 'Purchase Credits' button. The process is straightforward, and your balance updates instantly after the transaction.",
    },
    {
      question: "📁 What file formats are supported?",
      answer:
        "Chronocult currently supports exporting event data as CSV files, making it easy to import your results into other applications for further review or sharing.",
    },
    {
      question: "👥 Who can use Chronocult?",
      answer:
        "Chronocult is built for amateur teams, clubs, and individuals looking for a simple, no-frills timing solution. It's designed to be easy to set up and use, even if you're new to digital timing.",
    },
    {
      question: "🐞 How can I help improve Chronocult?",
      answer:
        "Since Chronocult is still in beta, your feedback is invaluable. If you encounter any bugs or have suggestions for improvements, please report them so we can make the app even better!",
    },
  ];

  const nameToDisplay = userData?.username || user?.displayName || user?.email || "Guest";
  const userInitials = user?.email ? user.email.match(/\b\w/g).join("").toUpperCase() : "U";

    if (!user) {
    // Optionally, you can return a loading spinner here.
    return null;
  }
  return (
    <div className="homepage-container">
      {/* Sidebar – 30% width */}
      <div className="homepage-sidebar">
        <div className="user-panel">
          <div className="user-icon">
          <img src={generateDiceBearAvatar(user.email)} className="user-avatar" alt="User Avatar" />
            {/* {userInitials} */}
            </div>
          <div className="user-info">
            <h3>{nameToDisplay || user?.email || "Guest"}</h3>
            <p>{user?.email || "No Email"}</p>
            <p>
              Credits: <strong>{userData?.credits ?? 0}  ⚡</strong>
            </p>
          </div>
          {user && shareGroupId && (
            <div style={{ marginTop: "10px", fontSize: "0.9em", color: "#888" }}>
              Public Sharing URL:{" "}
              <a
                href={`${window.location.origin}/events?id=${shareGroupId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {window.location.origin}/events?id={shareGroupId}
              </a><Tooltip textKey="shareURLTooltip" />
            </div>
          )}
          <button className="primary-btn" onClick={() => navigate("/buy-credits")}>
            Purchase Credits
          </button>
          <button className="secondary-btn" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>

      {/* Main content – 70% width */}
      <div className="homepage-main">
        <div className="homepage-other-tools">
          <p>
            Our timer tool is a simple solution to record racers’ times using just two devices—one at the start and one at the finish.
          </p>
          <div style={{ marginTop: "10px", textAlign: "left" }}>
            <button
              style={{
                background: "none",
                border: "none",
                color: "#007bff",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => setDemoModalTimerOpen(true)}
            >
              <FaVideo style={{ marginRight: "5px" }} /> view demo video
            </button>
          </div>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
  <h2>Your Events</h2>
  <button
    onClick={() => navigate("/timer")} // or a dedicated route to create a new event
    style={{
      background: "#13A1A8",
      border: "none",
      cursor: "pointer",
      fontSize: "1.5em",
      color: "#fff",
      borderRadius: "10%",
    }}
    title="Create New Event"
  >
    +
  </button>
</div>
<hr />
{events.length > 0 ? (
  <ul className="events-list" style={{ padding: 0, margin: 0 }}>
    {events.map((ev) => (
      <li
        key={ev.id}
        className="event-item"
        style={{
          display: "flex",
          alignItems: "center", 
          justifyContent: "space-between",
          padding: "10px",
          borderBottom: "1px solid #ccc",
          listStyle: "none",
        }}
      >
        <div>
        {editingEventId === ev.id ? (
        <input
          type="text"
          value={eventNameEdit}
          onChange={(e) => setEventNameEdit(e.target.value)}
          onBlur={() => {
            // On blur, update the event name if changed.
            if (eventNameEdit.trim() && eventNameEdit !== ev.name) {
              handleUpdateEventName(ev.id, eventNameEdit.trim());
            } else {
              setEditingEventId(null);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (eventNameEdit.trim() && eventNameEdit !== ev.name) {
                handleUpdateEventName(ev.id, eventNameEdit.trim());
              } else {
                setEditingEventId(null);
              }
            }
          }}
          style={{ fontSize: "1.2em", padding: "4px" }}
          autoFocus
        />
      ) : (
        <strong>{ev.name}</strong>
      )}
      <br />
          <br />
          <small>
            {ev.createdAt ? new Date(ev.createdAt).toLocaleString() : ""}
          </small>
          <br />
          <small>
  Finished: {ev.racers ? ev.racers.filter(r => r.startTime && r.finishTime).length : 0} / Total: {ev.racers ? ev.racers.length : 0}, On Course: {ev.racers ? ev.racers.filter(r => r.startTime && !r.finishTime).length : 0}
</small>
        </div>
        <div style={{ display: "flex", alignItems: "right" }}>
        { ev.userId === user.uid && (
          <>
                    {/* Edit Button */}
                    <button
            onClick={() => {
              setEditingEventId(ev.id);
              setEventNameEdit(ev.name);
            }}
            style={{ marginRight: "10px", width: "auto", }}
            className="primary-btn"
            title="Edit Event Name"
          >
            <FaPencilAlt />
          </button>
          <button
            onClick={() => handleDeleteEvent(ev.id)}
            style={{ marginRight: "10px", width: "auto", }}
            className="primary-btn"
            title="Delete Event"
          >
            <FaTrash />
          </button>
          </>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={() => navigate(`/timer?eventId=${ev.id}`)}
            className="primary-btn"
            style={{ marginRight: "10px" }}
          >
            Select
          </button>
          </div>
        </div>
      </li>
    ))}
  </ul>
) : (
  <p>You have no events yet.</p>
)}
          <button className="primary-btn" onClick={() => navigate("/timer")}>
            Create New Event
          </button>
          <br />
          <br /><br />
          <hr />
         
   {/* NEW: Manage Racers Section */}
<div className="manage-racers" style={{ marginBottom: "30px" }}>
  <h2>👥 Manage Racers</h2>
  

  {/* Racers Table */}
  <div
    className="racers-list"
    style={{
      maxHeight: "600px",
      overflowY: "auto",
      border: "1px solid #ccc",
      padding: "10px",
      borderRadius: "5px",
    }}
  >
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr style={{ borderBottom: "1px solid #ccc" }}>
          <th
            style={{ textAlign: "left", cursor: "pointer", padding: "5px" }}
            onClick={() => {
              // Toggle between alphabetical and most recent created sorting.
              if (racerSortType === "name") {
                setRacerSortType("created");
                setSortOrder("desc"); // most recent first
              } else {
                setRacerSortType("name");
                setSortOrder("asc"); // alphabetical ascending
              }
            }}
          >
            Name{" "}
            {racerSortType === "name" ? (
              <span style={{ fontSize: "0.8em" }}>
                {sortOrder === "asc" ? "A→Z" : "Z→A"}
              </span>
            ) : (
              <span style={{ fontSize: "0.8em" }}>Most Recent</span>
            )}
          </th>
          <th style={{ textAlign: "left", padding: "5px" }}><div style={{ marginBottom: "10px" }}>
    <label htmlFor="groupFilter" style={{ marginRight: "5px" }}>Filter by group:</label>
    <select
      id="groupFilter"
      value={groupFilter}
      onChange={(e) => setGroupFilter(e.target.value)}
      style={{ padding: "3px" }}
    >
      <option value="">All</option>
      {distinctGroups.map((group) => (
        <option key={group} value={group}>
          {group}
        </option>
      ))}
    </select>
  </div></th>
          <th style={{ padding: "5px", textAlign: "right" }}></th>
        </tr>
      </thead>
      <tbody>
        {filteredRacers.length > 0 ? (
          filteredRacers.map((racer) => (
            <tr key={racer.id} style={{ borderBottom: "1px solid #eee" }}>
              {/* Name column: Display name with edit button immediately following */}
              <td style={{ padding: "5px" }}>
                {editingRacerId === racer.id ? (
                  <input
                    type="text"
                    value={renameInput}
                    onChange={(e) => setRenameInput(e.target.value)}
                    onBlur={() => handleRenameRacer(racer.id, renameInput)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleRenameRacer(racer.id, renameInput);
                      }
                    }}
                    autoFocus
                    style={{ padding: "3px", width: "70%" }}
                  />
                ) : (
                  <>
                    <span>{racer.name}</span>
                    <button
                      onClick={() => {
                        setEditingRacerId(racer.id);
                        setRenameInput(racer.name);
                      }}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        marginLeft: "5px",
                        color: "#555", // dark grey
                      }}
                      title="Rename Racer"
                    >
                      <FaPencilAlt />
                    </button>
                  </>
                )}
              </td>

              {/* Groups column */}
              <td style={{ padding: "5px" }}>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                  {racer.groups && racer.groups.length > 0 ? (
                    racer.groups.map((tag, index) => (
                      <div
                        key={index}
                        style={{
                          background: "#555", // dark grey
                          color: "#fff",
                          borderRadius: "15px",
                          padding: "3px 8px",
                          marginRight: "5px",
                          marginBottom: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>{tag}</span>
                        <button
                          onClick={() => handleRemoveTag(racer.id, tag)}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#fff",
                          }}
                          title="Remove Tag"
                        >
                          <FaTimes size={10} />
                        </button>
                      </div>
                    ))
                  ) : (
                    <span style={{ marginRight: "5px" }}>No group</span>
                  )}
                  {showGroupInput[racer.id] ? (
                    <>
                      <input
                        type="text"
                        placeholder="New group"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleAddTag(racer.id, e.target.value);
                            setShowGroupInput((prev) => ({ ...prev, [racer.id]: false }));
                          }
                        }}
                        autoFocus
                        style={{ padding: "3px", marginTop: "5px" }}
                      />
                      <button
                        onClick={() =>
                          setShowGroupInput((prev) => ({ ...prev, [racer.id]: false }))
                        }
                        style={{
                          background: "#13A1A8", // turquoise
                          border: "none",
                          color: "#fff",
                          padding: "3px 6px",
                          borderRadius: "3px",
                          marginLeft: "5px",
                        }}
                        title="Cancel"
                      >
                        <FaTimes />
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() =>
                        setShowGroupInput((prev) => ({ ...prev, [racer.id]: true }))
                      }
                      style={{
                        background: "#13A1A8", // turquoise
                        border: "none",
                        color: "#fff",
                        padding: "3px 6px",
                        borderRadius: "3px",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                      title="Add Group"
                    >
                      +
                    </button>
                  )}
                </div>
              </td>

              {/* Actions column: Delete button aligned right */}
              <td style={{ padding: "5px", textAlign: "right" }}>
              {racer.createdBy === user.uid && (
                <button
                  onClick={() => handleRemoveRacer(racer.id)}
            style={{ marginRight: "10px", width: "auto", }}
            className="primary-btn"
                  title="Delete Racer"
                >
                  <FaTrash />
                </button>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3" style={{ textAlign: "center", padding: "10px" }}>
              No racers found.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>

  {/* Add Racer Widget (placed outside the table) */}
  <div style={{ marginTop: "15px", display: "flex", alignItems: "center" }}>
    <input
      type="text"
      value={newRacerName}
      onChange={(e) => setNewRacerName(e.target.value)}
      placeholder="New racer name"
      style={{ padding: "5px", width: "150px", marginRight: "10px" }}
    />
    <button
      onClick={handleAddRacer}
      style={{
        padding: "5px 10px",
        backgroundColor: "#13A1A8", // turquoise
        border: "none",
        color: "#fff",
        cursor: "pointer",
        borderRadius: "3px",
      }}
    >
      Add Racer : {creditCosts.CREATE_RACER}⚡
    </button>
  </div>
</div>

          {/* FAQ Section */}
          <br />
          <br />
          <hr />
          <div className="homepage-faq">
            <h2>FAQ</h2>
            <ul className="faq-list">
              {faqItems.map((item, index) => (
                <li key={index} className="faq-item">
                  <div className="faq-question" onClick={() => toggleFaq(index)}>
                    <FaQuestionCircle style={{ marginRight: "5px" }} />
                    {item.question}
                  </div>
                  {faqOpenIndex === index && (
                    <div className="faq-answer">
                      {item.answer}{" "}
                      <span role="img" aria-label="smile">
                        <FaRegSmile />
                      </span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {demoModalMainOpen && (
        <div className="modal-overlay" onClick={() => setDemoModalMainOpen(false)}>
          <div className="modal" onClick={(e) => e.stopPropagation()} style={{ position: "relative", width: "80%" }}>
            <button className="modal-close-btn" onClick={() => setDemoModalMainOpen(false)}>
              <FaTimes />
            </button>
            <h3>Demo Video – Video/Data Analysis Tool</h3>
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
              <iframe
                title="Demo Video"
                src="https://www.youtube.com/embed/VVgDmG3AIX8"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      )}
      {demoModalTimerOpen && (
        <div className="modal-overlay" onClick={() => setDemoModalTimerOpen(false)}>
          <div className="modal" onClick={(e) => e.stopPropagation()} style={{ position: "relative", width: "80%" }}>
            <button className="modal-close-btn" onClick={() => setDemoModalTimerOpen(false)}>
              <FaTimes />
            </button>
            <h3>Demo Video – Easy Timer</h3>
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
              <iframe
                title="Demo Video Timer"
                src="https://www.youtube.com/embed/lkSw70TJ71o"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Homepage;