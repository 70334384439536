import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase'; // your firebase.js export

// Create the context
const AuthContext = createContext();

// Provide the context to children
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listen for auth changes (login, logout, etc.)
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Sign out function
  const logout = () => signOut(auth);

  return (
    <AuthContext.Provider value={{ user: currentUser, logout }}>
      {/* Only render children once we know the auth state (avoids flicker) */}
      {!loading && children}
    </AuthContext.Provider>
  );
}

// Custom hook for easy usage of AuthContext
export function useAuth() {
  return useContext(AuthContext);
}