import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Make sure you have a CSS file for styling

const Footer = () => {
  return (
    <footer className="footer-container">
      <p>&copy; 2025 Victorise. All rights reserved.</p>
      <nav className="footer-navigation">
        <Link to="/tos" className="footer-link">Terms of Service</Link> | 
        <Link to="/privacypolicy" className="footer-link"> Privacy Policy</Link>
      </nav>
    </footer>
  );
};

export default Footer;