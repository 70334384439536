import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, setDoc, getDoc, addDoc, collection } from "firebase/firestore";
import { FaUser, FaLock, FaUserPlus } from "react-icons/fa";
import "../App.css";

// Import Firebase services
import { auth, db } from "../firebase";

const Login = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Check if signup=true is present in the URL
    const params = new URLSearchParams(location.search);
    if (params.get("signup") === "true") {
      setIsSignUp(true);
    }
  }, [location.search]);

  if (isAuthenticated) {
    return <Navigate to="/homepage" />;
  }

  // CREATE ACCOUNT
  const handleSignUp = async () => {
    setError("");
    if (!email || !password) {
      setError("Email and password are required");
      return;
    }
    try {
      // Create the user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Create the document in Firestore with accepted=true and initial credits
      await setDoc(doc(db, "users", user.uid), {
        email,
        username,
        accepted: true,
        credits: 100,
        origin: "chronocult.com",
      });

      const shareGroupRef = doc(db, "sharegroups", user.uid); // Using user.uid as document id
      await setDoc(shareGroupRef, {
        ownerId: user.uid,
        name: "public",
        createdAt: Date.now(),
      });


      // Create a mail doc in "mail" collection (optional)
      await addDoc(collection(db, "mail"), {
        to: "mail@jeromelacote.com",
        message: {
          subject: "New user signup!",
          text: `A new user signed up with email: ${email}`,
        },
      });
      await addDoc(collection(db, "mail"), {
        to: email,
        message: {
          subject: "Welcome to Chronocult!",
          text: `Hello and welcome to Chronocult!
      
      Thank you for creating an account. You can now use our application to time your team. As a welcome gift, we have granted you 150 credits to help you get started.
      
      If you have any questions, please visit our website at www.chronocult.com.
      
      Enjoy exploring our tools!
      
      Best regards,
      The Chronocult Team`
        },
      });

      alert(
        "Account created! You have been granted 100 credits to start exploring our tools."
      );
      setIsSignUp(false);
      setEmail("");
      setPassword("");
    } catch (err) {
      console.error("Error creating account:", err);
      setError(err.message);
    }
  };

  // LOGIN
  const handleLogin = async () => {
    setError("");
    if (!email || !password) {
      setError("Email and password are required");
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if user is accepted
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        setError("No user profile found. Contact admin.");
        return;
      }
      const userData = docSnap.data();
      if (userData.accepted) {
        setIsAuthenticated(true);
      } else {
        setError("Account not accepted yet. Please contact admin.");
      }
    } catch (err) {
      console.error("Error logging in:", err);
      setError(err.message);
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        {isSignUp ? (
          <FaUserPlus className="login-icon signup-icon" />
        ) : (
          <FaUser className="login-icon" />
        )}
        <h2 className="login-title">
          {isSignUp ? "Create Your Account" : "Login to Your Account"}
        </h2>
        <p className="login-description">
          Welcome to Chronocult. You're just one step away from accessing an easy-to-use time trial timing solution.
        </p>
      </div>

      {error && <p className="error-text">{error}</p>}

      {isSignUp && (
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="login-input"
        />
      )}

      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="login-input"
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="login-input"
      />

      {isSignUp ? (
        <button onClick={handleSignUp} className="primary-btn login-btn">
          Sign Up
        </button>
      ) : (
        <button onClick={handleLogin} className="primary-btn login-btn">
          Login
        </button>
      )}

      <p className="login-switch">
        {isSignUp ? "Already have an account?" : "No account yet?"}{" "}
        <span
          className="login-switch-link"
          onClick={() => {
            setIsSignUp(!isSignUp);
            setError("");
          }}
        >
          {isSignUp ? "Login" : "Create one"}
        </span>
      </p>
    </div>
  );
};

export default Login;