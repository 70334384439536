const tooltipText = {
  credits:
    "Credits enable you to process videos. Each processed video deducts a specified number of credits.",
  uploadVideo:
    "Click here to upload a video file for analysis.",
  extractGPS:
    "Extract GPS: This tool extracts GPS data embedded in your video file.",
  buyCredits:
    "Buy Credits: Need more credits? Click here to purchase additional credits.",
  syncData:
    "Sync Data: Align your telemetry data with your video by selecting the video frame that corresponds to the start of your data.",
  extractgps:
    "Extract GPS Details: This feature is available only for cameras that record GPS metadata. Currently, only select GoPro models with built-in GPS (e.g., Hero 5 Black, Hero 6 Black, Hero 7 Black, Hero 8 Black, Hero 9 Black, Hero 10 Black, and Hero 13 Black) support this feature. Note that the GoPro Hero 12, Hero 11, and Hero Session series do not support GPS recording. If your camera lacks GPS, no location data will be extracted.",
  dragdropsupportedfiles:
    "Supported Files: We support GPX, FIT, and CSV formats (CSV exported from the Brake Ace Desktop application).",
    linkVideos:
    "Link Videos: This tool allows you to link multiple videos together for simultaneous playback.",
    shareURLTooltip:
    "Use this URL to share results with others. You have the option to publish event results to this URL by clicking on the share button in event page then ' Share to Public URL'",
};

export default tooltipText;