import React from "react";
import '../App.css';

const TermsOfService = () => {
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <h1>Terms of Service</h1>
      <p>Last Updated: 01/01/25</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using Victorise, you agree to be bound by these Terms of Service and all applicable laws and regulations.
        If you do not agree with any of these terms, you are prohibited from using or accessing this site or any of our services.
      </p>

      <h2>2. Description of Services</h2>
      <p>
        Victorise provides tools for video processing, telemetry extraction, and data visualization.
        Our platform allows users to upload, process, and analyze GoPro footage, among other services.
        We reserve the right to modify, suspend, or discontinue any part of the service at any time without prior notice.
      </p>

      <h2>3. User Accounts and Security</h2>
      <p>
        When creating an account, you agree to provide accurate and complete information. 
        You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account.
        You agree to notify us immediately of any unauthorized use or security breach.
      </p>

      <h2>4. User Conduct</h2>
      <p>
        You agree not to misuse the services provided by Victorise, including but not limited to:
      </p>
      <ul>
        <li>Uploading illegal, infringing, or harmful content.</li>
        <li>Attempting to gain unauthorized access to our systems.</li>
        <li>Disrupting or interfering with our services.</li>
        <li>Violating any applicable local, national, or international laws.</li>
      </ul>

      <h2>5. Intellectual Property Rights</h2>
      <p>
        All content on Victorise, including text, graphics, logos, software, and videos, is the property of Victorise or its licensors and is protected by copyright and intellectual property laws.
        You are granted a limited, non-exclusive license to access and use our services for personal or business purposes, but you may not copy, modify, distribute, or exploit our content without explicit permission.
      </p>

      <h2>6. User-Generated Content</h2>
      <p>
        By uploading content (videos, telemetry data, or other materials) to Victorise, you grant us a worldwide, non-exclusive, royalty-free license to store, process, and display your content as part of our services.
        You retain ownership of your content, but you confirm that you have the necessary rights and permissions to upload it.
      </p>

      <h2>7. Privacy and Data Protection</h2>
      <p>
        Your use of our services is also governed by our <a href="/privacypolicy">Privacy Policy</a>. 
        By using Victorise, you acknowledge and agree that we may collect, store, and process your personal and telemetry data in accordance with our Privacy Policy.
      </p>

      <h2>8. Payment and Subscription</h2>
      <p>
        Some features of Victorise may require payment. If you subscribe to a paid plan, you agree to provide accurate billing information.
        All fees are non-refundable unless otherwise stated. We reserve the right to change our pricing or introduce new fees at any time.
      </p>

      <h2>9. Service Availability and Limitations</h2>
      <p>
        While we strive to provide uninterrupted service, we do not guarantee that Victorise will always be available or error-free.
        We may perform maintenance or updates that could temporarily impact service availability.
      </p>

      <h2>10. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your account if you violate these Terms of Service, engage in abusive behavior, or use our platform for unlawful purposes.
        You may also terminate your account at any time by contacting our support team.
      </p>

      <h2>11. Disclaimers and Limitation of Liability</h2>
      <p>
        Victorise is provided on an "as is" and "as available" basis. We disclaim all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </p>
      <p>
        To the maximum extent permitted by law, Victorise shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of the service.
      </p>

      <h2>12. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless Victorise, its employees, contractors, and affiliates from any claims, damages, losses, or liabilities arising from your use of our services or violation of these terms.
      </p>

      <h2>13. Governing Law and Dispute Resolution</h2>
      <p>
        These Terms of Service shall be governed by and construed in accordance with the laws of the United States of America.
        Any disputes arising from these terms shall be resolved through arbitration or mediation before resorting to litigation.
      </p>

      <h2>14. Changes to Terms</h2>
      <p>
        We may update these Terms of Service from time to time. If we make significant changes, we will notify users via email or a notification on our website.
        Your continued use of Victorise after such updates constitutes acceptance of the new terms.
      </p>

      <h2>15. Contact Us</h2>
      <p>
        If you have any questions about these Terms of Service, please contact us at <a href="mailto:contact@victorise.com">contact@victorise.com</a>.
      </p>
    </div>
  );
};

export default TermsOfService;