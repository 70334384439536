import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import tooltipText from "../config/tooltipText";
import "../App.css"; // Ensure styles are applied

const Tooltip = ({ textKey }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="tooltip-container">
      {/* Question Mark Icon */}
      <FontAwesomeIcon
        icon={faQuestionCircle}
        className="tooltip-icon"
        onClick={() => setIsVisible(true)}
      />

      {/* Modal Overlay */}
      {isVisible && (
        <div className="tooltip-modal-overlay" onClick={() => setIsVisible(false)}>
          <div className="tooltip-modal-content" onClick={(e) => e.stopPropagation()}>
            {/* Close Button in the Corner */}
            <FontAwesomeIcon
              icon={faTimes}
              className="tooltip-close-btn"
              onClick={() => setIsVisible(false)}
            />
            
            {/* Tooltip Text */}
            <div className="tooltip-modal-body">
              {tooltipText[textKey] || "Tooltip text not found"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;