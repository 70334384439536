import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { functions } from "../firebase"; // Ensure this imports your Firebase config
import { FaArrowLeft } from "react-icons/fa"; // Import back button icon
import "../App.css"; // Ensure you have styles

//const stripePromise = loadStripe("pk_test_51PvMe104cj6eP83QW6M6JdfBNFpkbztWfAtrgqfHOvQO2epjXMv1nioRZYRVU8Fk8WzsJIDFxEyAXJ7QYDmqoVar007CjEOs3Z");
const stripePromise = loadStripe("pk_live_51PvMe104cj6eP83Qrs7WxXpNbz7tDDI91cNKkZXwuK0IkhtVMBQRald8O1da9kcoN0y4Bt9QZdTwiihB4unshts400PdgUelOz");

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const amount = searchParams.get("amount");
  const price = parseInt(searchParams.get("price"), 10);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) return;
  
    try {
      const createPaymentIntent = httpsCallable(getFunctions(), "createPaymentIntent");
  
      // 1️⃣ Call Firebase Callable Function
      const result = await createPaymentIntent({ amount: price });
      const clientSecret = result.data.clientSecret;
  
      if (!clientSecret) {
        throw new Error("Failed to get client secret.");
      }
  
      // 2️⃣ Confirm Payment using Client Secret
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
  
      if (error) {
        alert("Payment failed: " + error.message);
      } else {
        // 3️⃣ Get Current User Credits
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);
  
        if (!userSnap.exists()) {
          throw new Error("User document not found");
        }
  
        const currentCredits = userSnap.data().credits || 0;
  
        // 4️⃣ Update Firebase User Credits
        await updateDoc(userRef, {
          credits: currentCredits + parseInt(amount, 10),
        });
  
        alert("Payment successful! Credits added.");
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Payment Error:", error);
      alert("Payment error: " + error.message);
    }
  };

  return (
    <div className="checkout-container">
      <h2 className="checkout-title">Confirm Purchase</h2>
      <p className="checkout-subtitle">You are buying <strong>{amount} credits</strong> for <strong>${price}</strong>.</p>

      <form onSubmit={handleSubmit} className="checkout-form">
        <div className="card-input">
          <CardElement className="card-element" />
        </div>

        <div className="checkout-buttons">
          <button className="back-button" onClick={() => navigate("/buy-credits")}>
            <FaArrowLeft className="button-icon" /> Back
          </button>
          
          <button type="submit" className="buy-button" disabled={!stripe}>
            Pay Now
          </button>
        </div>
      </form>
    </div>
  );
};

const Checkout = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default Checkout;